



































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import LinkStatusModerateDialogViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/link-status-moderate/link-status-moderate-dialog-view-model';

@Component({
  name: 'LinkStatusModerateDialog',
  components: {
    LinkStatusTransferScheduled: () => import('./LinkStatusTransferScheduled.vue'),
    LinkStatusTransferRequested: () => import('./LinkStatusTransferRequested.vue'),
  },
})
export default class LinkStatusModerateDialog extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  @PropSync('goalName', { type: String })
  synced_goal_name!: string;

  @PropSync('linkStatus', { type: String })
  synced_link_status!: string;

  link_status_moderate_wealth_model = Vue.observable(
    new LinkStatusModerateDialogViewModel(this),
  );

  mounted() {
    this.link_status_moderate_wealth_model.setLinkStatus(
      this.synced_link_status,
    );
  }

  closeModal() {
    this.synced_is_open = false;
  }
}
