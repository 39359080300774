import Vue from 'vue';

export default class LinkStatusModerateDialogViewModel {
  readonly view: Vue;

  readonly STEPS = [
    {
      step: 0,
      component: 'LinkStatusTransferScheduled',
    },
    {
      step: 1,
      component: 'LinkStatusTransferRequested',
    },
  ];

  current_step = 0;

  final_step = this.STEPS.length - 1;

  current_component = this.STEPS[this.current_step];

  public constructor(view: Vue) {
    this.view = view;
  }

  menu_steps = [1, 2]

  get dynamic_width() {
    // eslint-disable-next-line no-nested-ternary
    return (this.view.$vuetify.breakpoint.xs) ? '100%' : (this.view.$vuetify.breakpoint.sm) ? '80%' : '40%';
  }

  setLinkStatus(status: string) {
    switch (status) {
      case 'in_progress':
      case 'unlinked_in_progress':
        this.current_step = 0;
        break;
      case 'balance_transfer_in_process':
      case 'unlinked_balance_transfer_in_process':
        this.current_step = 1;
        break;
      default:
        this.current_step = 0;
        break;
    }
    this.current_component = this.STEPS[this.current_step];
  }

  endProcess = () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
    this.view.$emit('closeModal');
  }
}
