import { render, staticRenderFns } from "./LinkStatusModerateDialog.vue?vue&type=template&id=ac636d84&"
import script from "./LinkStatusModerateDialog.vue?vue&type=script&lang=ts&"
export * from "./LinkStatusModerateDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VCard,VCol,VContainer,VDialog,VIcon,VStepper,VStepperHeader,VStepperStep})
